import styles from './DropDown.module.scss';
import {
  unstable_Form as ReaKitForm,
  unstable_FormRadioGroup as FormRadioGroup,
  unstable_FormRadio as FormRadio,
} from 'reakit/Form';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

const DropDown = ({ items, form, name }) => {
  return (
    <FormRadioGroup className={styles.dropdownContainer} {...form} name={name}>
      {items?.map(({ label, value }) => (
        <label
          className={classNames(styles.dropdownLabel, {
            [styles.activeDropdownItem]: form.values[name] === value,
          })}
        >
          <FormRadio {...form} name={name} value={value} /> {label}
        </label>
      ))}
    </FormRadioGroup>
  );
};

DropDown.propTypes = {
  items: PropTypes.arrayOf([{}]).isRequired,
  form: PropTypes.objectOf(ReaKitForm).isRequired,
  name: PropTypes.string.isRequired,
};

export default DropDown;
