import styles from './Selector.module.scss';
import React from 'react';
import { Popover, PopoverDisclosure } from 'reakit/Popover';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import classNames from 'classnames';

const Selector = ({
  shortName,
  children,
  name,
  popover,
  icon,
  label,
  isEmpty,
  hasManualTrigger,
  noSpacing,
  placeholder,
  disabled,
}) => {
  const ItemTag = !hasManualTrigger ? PopoverDisclosure : 'div';

  return (
    <div className={styles.selectorWrapper}>
      <ItemTag
        disabled={disabled}
        className={styles.selectorDisclosure}
        {...popover}
      >
        {/** check polite */}
        <span aria-label="You selected" aria-live="polite">
          <div className={styles.label}>{name}</div>
          <div disabled={disabled} className={styles.dropdownWrapper}>
            {icon}
            <div className={styles.textLabel}>
              {label}
              {isEmpty === true && (
                <div className={styles.emptyValue}>{placeholder}</div>
              )}
              {shortName && (
                <span className={styles.shortName}>{shortName}</span>
              )}
            </div>

            {popover && (
              <IconComponents.SmallArrowDown
                className={styles.expandButtonIcon}
              />
            )}
          </div>
        </span>
      </ItemTag>
      <Popover
        className={classNames(styles.popOver, {
          [styles.noSpacing]: noSpacing,
        })}
        {...popover}
        aria-label={name}
      >
        {children}
      </Popover>
    </div>
  );
};

export default Selector;
