import styles from './CreateUser.module.scss';
import Layout from 'components/Layout/Layout';
import Container from 'components/Container/Container';
import DropDown from 'components/DropDown/DropDown';
import DataView from 'components/DataView/DataView';
import BackNavigation from 'components/Navigation/BackNavigation';
import Client from 'api/client';
import Selector from 'components/Selector/Selector';
import React, { useEffect } from 'react';
import {
  unstable_FormLabel as FormLabel,
  unstable_FormSubmitButton as FormSubmitButton,
  unstable_useFormState as useFormState,
  unstable_FormMessage as FormMessage,
} from 'reakit/Form';
import { navigate } from 'gatsby';
import { usePopoverState } from 'reakit/Popover';
import Heading from '@moonshineragency/ui/src/components/Heading/Heading';
import InputField from '@moonshineragency/ui/src/components/InputField/InputField';
import Button from '@moonshineragency/ui/src/components/Button/Button';
import Link from '@moonshineragency/ui/src/components/Link/Link';
import Form, {
  renderInputErrorFor,
} from '@moonshineragency/ui/src/components/Form/Form';

const CreateUser = ({
  layoutStructure = {
    showBreadCrumbs: false,
    showTitle: false,
  },
}) => {
  const popover = usePopoverState();

  const userRoles = typeof window !== 'undefined' && window.history.state;

  const form = useFormState({
    values: {
      ...userRoles,
      first_name: '',
      last_name: '',
      email: '',
      role: '',
    },
    onValidate: values => {
      let errors = {};
      if (!values.first_name) {
        errors = {
          ...errors,
          first_name: renderInputErrorFor('Please enter first name'),
        };
      }
      if (!values.last_name) {
        errors = {
          ...errors,
          last_name: renderInputErrorFor('Please enter last name'),
        };
      }
      if (!values.email) {
        errors = {
          ...errors,
          email: renderInputErrorFor('Please enter email'),
        };
      }

      if (Object.keys(errors).length) {
        throw errors;
      }
    },
    onSubmit: async (values, errors) => {
      const requestValues = { ...values, role: values.role.toLowerCase() };
      try {
        await Client.createUser.post(requestValues);
        navigate(`/admin/users/list`);
      } catch (e) {
        throw errors;
      }
    },
  });

  const renderUpperCase = item =>
    item !== null && item.charAt(0).toUpperCase() + item.slice(1);

  const rolesOptions = form?.values?.userRoles?.map(userRole => userRole.name);
  const options = rolesOptions?.map(roleOption => {
    return {
      value: renderUpperCase(roleOption),
      label: renderUpperCase(roleOption),
    };
  });

  useEffect(() => {
    if (form.values.role) {
      popover.hide();
    }
  }, [form.values]);
  return (
    <Layout isAdmin layoutStructure={layoutStructure}>
      <Container size="md">
        <BackNavigation to="/admin/users/list" label="All Users" />
        <br />
        <Heading size="h2" theme="primary">
          Create New User
        </Heading>
        <Form form={form}>
          <div className={styles.inputWrapper}>
            <div className={styles.inputField}>
              <InputField
                type="first_name"
                name="first_name"
                label="First name"
                {...form}
                noMessage
              />
              <FormMessage {...form} name="first_name" />
            </div>
            <div className={styles.inputField}>
              <InputField
                type="last_name"
                name="last_name"
                label="Last name"
                {...form}
                noMessage
              />
              <FormMessage {...form} name="last_name" />
            </div>
            <div className={styles.inputField}>
              <InputField
                type="email"
                name="email"
                label="Email address"
                {...form}
                noMessage
              />
              <FormMessage {...form} name="email" />
            </div>
            <div className={styles.inputField}>
              <div className={styles.selectRoleLabel}>
                <FormLabel {...form} name="role">
                  Role
                </FormLabel>
              </div>
              <Selector
                popover={popover}
                shortName="role"
                label={form.values.role || 'Select role'}
              >
                <DataView
                  popover={popover}
                  name="role"
                  className={styles.userSelectorWrapper}
                >
                  <DropDown name="role" items={options} form={form} />
                </DataView>
              </Selector>
            </div>
            <div className={styles.actionWrapper}>
              <div className={styles.cancel}>
                <Link
                  size="75"
                  type="text"
                  className={styles.cancelLink}
                  to="/admin/users/list"
                >
                  Cancel
                </Link>
              </div>
              <div className={styles.saveButtonWrapper}>
                <FormSubmitButton
                  as={Button}
                  theme="primary"
                  {...form}
                  className={styles.saveButton}
                >
                  Save
                </FormSubmitButton>
              </div>
            </div>
          </div>
        </Form>
      </Container>
    </Layout>
  );
};

export default CreateUser;
