import styles from 'components/Navigation/BackNavigation.scss';
import IconComponents from '@moonshineragency/ui/src/components/IconComponents/IconComponents';
import React from 'react';
import Link from '@moonshineragency/ui/src/components/Link/Link';
import IconWrapper from '@moonshineragency/ui/src/components/IconWrapper/IconWrapper';
import PropTypes from 'prop-types';

const BackNavigation = ({ label, to, className }) => {
  return (
    <div className={className}>
      <Link variant="noUnderline" to={to} className={styles.container}>
        <IconWrapper
          className={styles.backIcon}
          Icon={IconComponents.SmallArrowLongLeftIcon}
          alt="smallArrowLeft"
        />
        {label}
      </Link>
    </div>
  );
};

BackNavigation.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

BackNavigation.defaultProps = {
  className: '',
};

export default BackNavigation;
